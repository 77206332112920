<template>
  <div>
    <Wrapper>
      <Sidebar slot="sidebar"/>
      <Navbar slot="navbar"/>
      <Footer slot="footer"/>
      <div slot="content">
        <section id="tabs" class="project-tab">
          <div class="container-fluid">
            <nav>
              <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                <a
                  class="nav-item nav-link active"
                  id="nav-home-tab"
                  data-toggle="tab"
                  href="#nav-home"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="true"
                >{{tran.what_is_vest}}</a>
                <a
                  class="nav-item nav-link"
                  id="nav-profile-tab"
                  data-toggle="tab"
                  href="#nav-profile"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                >{{tran.investing}}</a>
              </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
              <div
                class="tab-pane fade show active mt-4"
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                <div class="display-div" style="min-height:40vh; align-items:center">
                  <div class="row">
                    <div class="col-md-3 col-3 pb-3"></div>
                    <div class="col-md-2 col-12 pb-3">
                      <div class="text-center">
                        <img src="../../../assets/images/womanph.png" class="img-fluid">
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <h6 class="pb-2">
                        <b>What is Vestlock</b>
                      </h6>
                      <p>You can compare Vestlock with discounted instruments like Treasury Bills where, for example, if you're investing N1 million at 10%, you get N100,000 paid into your account instantly, while you get N1 million when the treasury bill matures.</p>
                      <p class="pt-4">
                        <b>With Vestlock,</b>
                      </p>
                      <p
                        class="pt-1"
                      >- You have free funds (upfront interest) that you can spend, invest in yourself (a treat, maybe) or reinvest if you like</p>

                      <p
                        class="pt-2"
                      >- You lock down the fund for the fixed period, saving the temptation of liquidating (sell)</p>
                       <button class="vest-btn-2 mt-3"  @click="gotoVestlock">
                        <b> Goto Vestlock </b>
                      </button>
                    </div>
                  </div>
                </div>

                  <!-- <div class="row">
                    <div class="col-md-12 text-center">
                      <button class="vest-btn-2 mt-3"  @click="gotoVestlock">
                        <b> Goto Vestlock </b>
                      </button>
                    </div>
                  </div> -->
              </div>
              <div
                class="tab-pane fade"
                id="nav-profile"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
              >
                <div class="display-div" style="min-height:50vh; align-items:center">
                  <div class="row">
                    <div class="col-md-2 col-12 col-sm-6 mt-4">
                      <div class="text-center">
                        <img
                          src="../../../assets/images/womanph3.png"
                          :class="{'mob-img':windowWidth < 768}"
                          class="img-fluid"
                        >
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-6 mt-4" :class="{'mob':windowWidth < 768}">
                      <h6 class="pb-2">
                        <b>Is Vestlock different from the normal investment plan?</b>
                      </h6>
                      <p>Yes, your Vestlock is different from your normal investment plan. After initiating a Vestlock with a particular amount and investment, your interests get paid upfront.</p>
                    </div>

                    <div class="col-md-2 col-12 col-sm-6 mt-4">
                      <div class="text-center">
                        <img
                          src="../../../assets/images/womanph2.png"
                          :class="{'mob-img':windowWidth < 768}"
                          class="img-fluid"
                        >
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-6 mt-4" :class="{'mob':windowWidth < 768}">
                      <h6 class="pb-2">
                        <b>Can I have more than one Vestlock?</b>
                      </h6>
                      <p>Yes, you can have more than one Vestlock.</p>
                    </div>

                    <div class="col-md-2 col-sm-6 mt-4">
                      <div class="text-center">
                        <img
                          src="../../../assets/images/womanph4.png"
                          :class="{'mob-img':windowWidth < 768}"
                          class="img-fluid"
                        >
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-6 mt-4" :class="{'mob':windowWidth < 768}">
                      <h6 class="pb-2">
                        <b>How will I get my capital back?</b>
                      </h6>
                      <p>After the maturity of your investment, your wallet gets credited with your capital. Withdrawal then is at your disposal.</p>
                    </div>

                    <div class="col-md-2 mt-4">
                      <div class="text-center">
                        <img
                          src="../../../assets/images/womanph5.png"
                          :class="{'mob-img':windowWidth < 768}"
                          class="img-fluid"
                        >
                      </div>
                    </div>
                    <div class="col-md-4 col-12 mt-4" :class="{'mob':windowWidth < 768}">
                      <h6 class="pb-2">
                        <b>Can I liquidate on Vestlock?</b>
                      </h6>
                      <p>No, you can't liquidate your investment in Vestlock</p>
                    </div>
                  </div>
                </div>
                 <div class="row">
                    <div class="col-md-12 text-center">
                      <button class="vest-btn-2 mt-3 mb-2" @click="gotoVestlock">
                        <b> Goto Vestlock </b>
                      </button>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import Navbar from "../../partials/Navbar";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import Wrapper from "../../partials/Wrapper";
import trans from "../../../../translations";

import { commonJs } from "../mixins/commonJs.js";

import { mapState } from "vuex";

export default {
  mixins: [commonJs],
  data() {
    return {};
  },

  watch: {},
  methods: {},
  components: {
    Navbar,
    Sidebar,
    Wrapper,
    Footer
  },

  computed: {
    ...mapState({
      accountOverview: state => state.market.accountOverview
    })
  },

  methods:{

      gotoVestlock(){

          this.$router.push('/vest/home')
      }
  },
  mounted() {
    let heading = {
      main: this.tran.explainer_vest,
      sub: this.tran.explainer
    };

    this.$store.commit("market/savePageHeading", heading);
    this.tran = trans;
  }
};
</script>

<style scoped>
.mob {
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 10px;
}

.mob-img {
  height: 100 !important;
}
.nav-tabs {
  max-width: 281px;
  margin: 0 auto;
}

.project-tab {
  padding: 10%;
  margin-top: -8%;
}

.project-tab #tabs {
  background: #007b5e;
  color: #000;
}

.project-tab #tabs h6.section-title {
  color: #000;
}

.project-tab #tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #0094ff;
  background-color: transparent;
  border-color: transparent transparent #f3f3f3;
  border-bottom: 2px solid !important;
  font-size: 16px;
  font-weight: bold;
}

.project-tab .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

.project-tab thead {
  background: #f3f3f3;
  color: #333;
}
.project-tab a {
  text-decoration: none;
  color: #333;
  font-weight: 600;
}
</style>
