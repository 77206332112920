<template>
<div>
    <VestLockExplainer />
</div>
</template>

<script>
import VestLockExplainer from '../../components/dashboard/helpSupport/VestLockExplainer'
export default {
    components: {
        VestLockExplainer
    }
}
</script>

<style>
    